import React from 'react';
import './Footer.css';

import JacarandaLogo from '../../../Images/Logos/jacaranda.png';
import TrackerLogo from '../../../Images/Logos/tracker.png';
// import JacarandaLogo from './Logos/JacarandaLogo';
// import TrackerLogo from './Logos/TrackerLogo';
import FacebookLogo from './Logos/FacebookLogo';
import TwitterLogo from './Logos/TwitterLogo';
import LinkedInLogo from './Logos/LinkedInLogo';
import InstagramLogo from './Logos/InstagramLogo';

export default function Footer({ openTerms }) {
  return (
    <footer className='footer'>
      <div className='footer-content'>
        <div className='grid'>
          <div className='jacaranda-logo'>
            <a target='_blank' rel='noreferrer' href='https://www.jacarandafm.com/'>
              <img src={JacarandaLogo} alt='Jacaranda Logo' />
            </a>
          </div>
          <div className='tracker-logo'>
            <a target='_blank' rel='noreferrer' href='https://www.tracker.co.za/'>
              <img src={TrackerLogo} alt='Tracker Logo' />
            </a>
          </div>
          <div className='social-media'>
            <a target='_blank' href='https://www.facebook.com/Jacaranda942/'>
              <FacebookLogo />
            </a>
            <a target='_blank' href='https://twitter.com/jacarandafm'>
              <TwitterLogo />
            </a>
            {/* 
            <a target='_blank' href=''>
              <LinkedInLogo />
            </a> */}
            <a target='_blank' href='https://www.instagram.com/jacarandafm'>
              <InstagramLogo />
            </a>
          </div>
          <div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href='#'
              onClick={(e) => {
                e.preventDefault();
                openTerms();
              }}>
              TERMS AND CONDITIONS
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
