if (window.TreasureHunt == null) {
  let loaderTimeout;
  window.TreasureHunt = {
    /**
     *
     * @param {int} index - Index of  icon to display
     * @param {object} options - Options for displaying icon
     * @param {bool} options.transition - Set `false` to disable transitioning icons. Default `true`
     * @param {bool} options.displayMessage - Set `false` to disable showing treasure found popdown. Default `true`
     * @param {bool} options.displayConfetti - Set `false` to disable showing confettis. Default `true`
     */
    setTreasureFound(index, options = {}) {
      const event = new CustomEvent('treasureHunt.found', {
        detail: {
          index,
          ...options,
        },
      });
      document.dispatchEvent(event);
    },
    reset() {
      const event = new Event('treasureHunt.reset');
      document.dispatchEvent(event);
    },
    showLoader(timeout = 2000) {
      const event = new Event('treasureHunt.loader.show');
      document.dispatchEvent(event);

      if (loaderTimeout != null) clearTimeout(loaderTimeout);
      loaderTimeout = setTimeout(() => {
        const event = new Event('treasureHunt.loader.hide');
        document.dispatchEvent(event);
      }, timeout);
    },
    showWelcomeDialog() {
      const event = new Event('treasureHunt.welcomeDialog');
      document.dispatchEvent(event);
    },
  };
}
