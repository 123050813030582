import React, { useState, useRef } from 'react';
import Loader from '../../Loader/Loader';
import { isBadWord } from './badWords';
import './SignUpForm.css';

import MsanziLogo from '../../../Images/Backgrounds/mzanzi_quest_symbol.png';

// eslint-disable-next-line
const EMAIL_REGEX =
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
const PHONE_NUMBER_REGEX = /^(0\d?|\+?27[\s]+)\d{2}[\s-]?\d{3}[\s-]?\d{4}$/;
const MAX_NAME_LENGTH = 2;
const EMPTY_STRING = '';

export default function SignUpForm({ onSubmit, openTerms, showGeneralError, isSigningUp, showFullError }) {
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState(EMPTY_STRING);
  const [firstName, setFirstName] = useState(EMPTY_STRING);
  const [surnameErrorMessage, setSurnameErrorMessage] = useState(EMPTY_STRING);
  const [surname, setSurname] = useState(EMPTY_STRING);
  const [mobileNumberErrorMessage, setMobileNumberErrorMessage] = useState(EMPTY_STRING);
  const [mobileNumber, setMobileNumber] = useState(EMPTY_STRING);
  const [emailErrorMessage, setEmailErrorMessage] = useState(EMPTY_STRING);
  const [email, setEmail] = useState(EMPTY_STRING);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isAgreeToTermsRequired, setIsAgreeToTermsRequired] = useState(false);
  const firstNameInput = useRef(null);
  const surnameInput = useRef(null);
  const mobileNumberInput = useRef(null);
  const emailInput = useRef(null);
  const skipTutorialCheckbox = useRef(null);
  const agreeTermsCheckbox = useRef(null);
  const [showUnavailableError, setShowUnavailableError] = useState(false);
  const [showThankyou, setShowThankyou] = useState(true);
  const [showNeedToRegisterError, setShowNeedToRegisterError] = useState(false);
  const [showClosedError, setShowClosedError] = useState(false);

  let hasSubmitted = useRef(false);


  window.onload = function() {
    
    function parse_query_string(query) {
      var vars = query.split("&");
      var query_string = {};
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        var key = decodeURIComponent(pair[0]);
        var value = decodeURIComponent(pair[1]);
        // If first entry with this name
        if (typeof query_string[key] === "undefined") {
          query_string[key] = decodeURIComponent(value);
          // If second entry with this name
        } else if (typeof query_string[key] === "string") {
          var arr = [query_string[key], decodeURIComponent(value)];
          query_string[key] = arr;
          // If third or later entry with this name
        } else {
          query_string[key].push(decodeURIComponent(value));
        }
      }
      return query_string;
    }

    var query = window.location.search.substring(1);
    var qs = parse_query_string(query);

    // console.log(qs);

    if (qs.is_category_full) {

      console.log('is full');
      setShowUnavailableError(true);
    } else if (qs.is_not_registered) {
      console.log('is not registered');
      setShowNeedToRegisterError(true);
    } else {

      function checkClosedTime() {


        var d = new Date();
        var h = d.getHours();
        var mins = d.getMinutes();
        var secs = d.getSeconds();
        console.log(h + ":" + mins + ":" + secs);
        // console.log(mins);
        // console.log(secs);

        if (h < 8 || h >= 20) {
          // alert("We are closed");
          // setShowClosedError(true);
        } else {
          // setShowClosedError(false);
        }
      }

      setInterval(function(){
        // console.log('check');
        checkClosedTime();
      },1000);

      checkClosedTime();

    }

  }



  const validateForm = (focusElement = false) => {
    if (!hasSubmitted.current) return;
    let isValid = true;
    let focusInput;
    const _firstName = firstName.trim();
    const _surname = surname.trim();
    const _mobileNo = mobileNumber.trim();
    const _email = email.trim();

    // Validate first name
    if (_firstName.length < MAX_NAME_LENGTH) {
      isValid = false;
      focusInput = focusInput || firstNameInput;
      setFirstNameErrorMessage(`First Name requires at least  ${MAX_NAME_LENGTH} characters.`);
    } else if (isBadWord(_firstName)) {
      isValid = false;
      focusInput = focusInput || firstNameInput;
      setFirstNameErrorMessage(`That word is not allowed. Please use another name.`);
    } else {
      setFirstNameErrorMessage(EMPTY_STRING);
    }

    // Validate surname
    if (_surname.length < MAX_NAME_LENGTH) {
      isValid = false;
      focusInput = focusInput || surnameInput;
      setSurnameErrorMessage(`Surname requires at least ${MAX_NAME_LENGTH} characters.`);
    } else {
      setSurnameErrorMessage(EMPTY_STRING);
    }

    // Validate mobile number
    if (!_mobileNo) {
      isValid = false;
      focusInput = focusInput || mobileNumberInput;
      setMobileNumberErrorMessage('Mobile Number is required.');
    } else if (!PHONE_NUMBER_REGEX.test(_mobileNo)) {
      isValid = false;
      focusInput = focusInput || mobileNumberInput;
      setMobileNumberErrorMessage('Mobile Number is not valid.');
    } else {
      setMobileNumberErrorMessage(EMPTY_STRING);
    }

    // Validate email
    if (_email && !EMAIL_REGEX.test(_email)) {
      isValid = false;
      focusInput = focusInput || emailInput;
      setEmailErrorMessage('E-mail is not valid');
    } else {
      setEmailErrorMessage(EMPTY_STRING);
    }

    // Validate Agree
    if (!agreeTermsCheckbox.current.checked) {
      isValid = false;
      focusInput = agreeTermsCheckbox;
      setIsAgreeToTermsRequired(true);
    } else {
      setIsAgreeToTermsRequired(false);
    }

    if (focusElement && focusInput) {
      focusInput.current.focus();
    }

    return isValid;
  };

  const resetForm = () => {
    hasSubmitted.current = false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    hasSubmitted.current = true;
    const isValid = validateForm(true);

    if (isValid) {
      resetForm();

      if (typeof onSubmit === 'function') {
        onSubmit({
          firstName: firstName.trim(),
          surname: surname.trim(),
          mobileNumber: mobileNumber.trim(),
          email: email || null,
          skipTutorial: skipTutorialCheckbox.current.checked ? true : false,
        });
      }
    }
  };

  return (
    <div id='signup' className='signup-page'>
      <div className='signup-content'>
        <img className='msanzi-logo' src={MsanziLogo} alt='Msanzi Quest Logo' />
        <div>
          <div className='signup-form'>
            <Loader isShowing={isSigningUp} />
            <h1 class='thankyou'>Thank you for joining us on Mzansi Quest!</h1>
            {showClosedError && (
              <div className='error-alert'>
               Registration is closed for now.
               <br/>Please visit us again anytime from
               <br/>08h00 - 20h00 CAT to join the quest.
               <br/>
               <br/>If you’ve played the quest, tune in to Breakfast with Martin Bester everyday from 06h00 - 09h00 to hear if you’re a lucky winner.
              </div>
            )}
            {showNeedToRegisterError && (
              <div className='error-alert'>
               You need to register in order to access the Mzansi Quest experience.
              </div>
            )}
            {showUnavailableError && (
              <div className='error-alert'>
                Mzansi Quest is currently unavailable, due to high demand.<br/> We apologise for the inconvenience, and are working to resolve the issue.<br/> Please try again later.
              </div>
            )}
            {showGeneralError && (
              <div className='error-alert'>
                There's an issue with Mzansi Quest, we are working on it, sorry for the inconvenience, please try again later.
              </div>
            )}
            {showFullError && <div className='error-alert'>The Mzansi Quest is full, please try again later.</div>}

            {!showClosedError && !showThankyou && !showUnavailableError && (
            <form onSubmit={handleSubmit}>
              <div className='input-group'>
                <input
                  type='text'
                  placeholder='First Name'
                  ref={firstNameInput}
                  onChange={(e) => setFirstName(e.target.value)}
                  onKeyUp={(e) => {
                    if (firstName.trim() !== EMPTY_STRING) {
                      validateForm();
                    }
                  }}
                />
                <div className='input-border'></div>
                <div className='error-message'>{firstNameErrorMessage}</div>
              </div>
              <div className='input-group'>
                <input
                  type='text'
                  placeholder='Surname'
                  ref={surnameInput}
                  onChange={(e) => setSurname(e.target.value)}
                  onKeyUp={(e) => {
                    if (surname.trim() !== EMPTY_STRING) {
                      validateForm();
                    }
                  }}
                />
                <div className='input-border'></div>
                <div className='error-message'>{surnameErrorMessage}</div>
              </div>
              <div className='input-group'>
                <input
                  type='tel'
                  placeholder='Mobile Number  (e.g. 0831234567)'
                  autoComplete='tel'
                  ref={mobileNumberInput}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  onKeyUp={(e) => {
                    if (mobileNumber.trim() !== EMPTY_STRING) {
                      validateForm();
                    }
                  }}
                />
                <div className='input-border'></div>
                <div className='error-message'>{mobileNumberErrorMessage}</div>
              </div>
              <div className='input-group'>
                <input
                  type='email'
                  placeholder='E-mail Address'
                  autoComplete='email'
                  ref={emailInput}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyUp={(e) => {
                    if (email.trim() !== EMPTY_STRING) {
                      validateForm();
                    }
                  }}
                />
                <div className='input-border'></div>
                <div className='error-message'>{emailErrorMessage}</div>
              </div>
              <div className='input-toggle-group'>
                <input id='skip_tutorial' type='checkbox' ref={skipTutorialCheckbox} /> <label htmlFor='skip_tutorial'>Skip Tutorial?</label>
              </div>
              <div className='input-toggle-group'>
                <input
                  id='agree_terms'
                  type='checkbox'
                  ref={agreeTermsCheckbox}
                  className={`${isAgreeToTermsRequired ? 'error' : ''}`}
                  onChange={(e) => {
                    // setIsSubmitDisabled(!e.target.checked);
                  }}
                />
                <div className='agree-text'>
                  <label htmlFor='agree_terms'>Agree to</label>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href='#'
                    onClick={(e) => {
                      e.preventDefault();
                      openTerms();
                    }}>
                    Terms and Conditions
                  </a>
                </div>
              </div>
              <button type='submit' className='submit-button' disabled={isSubmitDisabled}>
                START QUEST
              </button>
            </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
