import React, { useEffect, useRef } from 'react';
import './TermsAndConditions.css';

export default function TermsAndConditions({ isOpen, onClose }) {
  const bodyOverflow = useRef(null);
  useEffect(() => {
    if (isOpen) {
      bodyOverflow.current = document.body.style.overflow;
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = bodyOverflow.current;
    }
  }, [isOpen]);

  return (
    <div className={`terms-and-conditions ${isOpen ? 'show' : ''}`}>
      <div className='content'>
        <div className='topbar'>
          <h1 className='title'>TERMS AND CONDITIONS</h1>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='close-button'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
            onClick={(e) => onClose()}>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M6 18L18 6M6 6l12 12' />
          </svg>
        </div>
        <h2>GENERAL RULES</h2>
        <h3>1 Definitions</h3>
        <ul>
          <li>
            1.1 "<b>Competition</b>" means any promotion, prize draw, giveaway or competition operated by Jacaranda FM, including but not limited to
            those Competitions operated by Jacaranda FM on behalf of its sponsors or any radio station or website owned or controlled (directly or
            indirectly) by Jacaranda FM from time to time;
          </li>
          <li>
            1.2 "<b>Jacaranda FM</b>" means Jacaranda FM, a broadcasting station owned by Kagiso Tiso Holdings Proprietary Limited (registration
            number 2011/000848/07);
          </li>
          <li>
            1.3 "<b>Personal</b> Information" means personal information as defined in POPIA;
          </li>
          <li>
            1.4 "<b>POPIA</b>" means the Protection of Personal Information Act 4 of 2013;
          </li>
          <li>
            1.5 "<b>Process</b>"/"<b>Processing</b>" has the same meaning assigned thereto in POPIA;
          </li>
          <li>
            1.6 "<b>Rules</b>" means these terms and conditions including specific terms and conditions, rules or entry instructions published on the
            Jacaranda FM Radio website or in any media which features a competition (e.g. on-air messages).
          </li>
          <li>
            1.7 "<b>you</b>", "<b>your</b>" means the person who has entered into a Competition.
          </li>
        </ul>
        <h3>2 Introduction</h3>
        <ul>
          <li>
            2.1
            <b>
              You must take time to read these Rules carefully and make sure you understand the Rules before entering any Competition. You must pay
              special attention to all text in bold.
            </b>
          </li>
          <li>2.2 These Rules shall be binding on you when you enter any Competition.</li>
          <li>
            2.3
            <b>
              You do not have to pay anything or buy anything to be able to participate in any Competition. If your entry is posted or sent using the
              internet, short message service (SMS), multimedia messaging service (MMS) or any similar media or device, you will have to pay the
              reasonable costs of posting the entry form or of transmitting it. This cost will not be more than the normal fee you will pay to your
              service provider or for using the internet.
            </b>
          </li>
          <li>
            2.4 Incomplete, incorrect or indecipherable entries will be void.  Unless otherwise stated, photocopies (e.g. of entries or tokens) will
            not be accepted.
          </li>
          <li>
            2.5 Jacaranda FM reserves the right, in its reasonable discretion, to add to, amend, or waive any of the Rules on reasonable notice to
            you, including but not limited to extending or reducing the duration of a Competition, and giving clues.
          </li>
        </ul>
        <h3>3 Eligibility to enter Competitions</h3>
        <ul>
          <li>
            3.1 In order to qualify as an entrant, you must – 
            <ul>
              <li>3.1.1 be a resident of South Africa and in possession of a valid identity document;</li>
              <li>3.1.2 be at least 18 years old, or if not, assisted by a parent or legal guardian; and</li>
              <li>3.1.3 provide correct and full personal details when entering the Competition.</li>
            </ul>
          </li>
          <li>
            3.2
            <b>
              Automated entries or syndicated entries are prohibited, and any use of such devices or schemes will cause disqualification of all such
              entries.
            </b>
            <br />
            <br />
            Jacaranda FM, its distributors, retailers, advertising and promotional agencies, directors, partners, employees, agents, consultants,
            subcontractors, and their respective spouses, life partners, business partners or immediate family member are not eligible to participate
            in any Competition. 
          </li>
          <li>
            3.3 Unless otherwise stated –
            <ul>
              <li>3.3.1 only one entry per person will be permitted. Multiple entries from one person will cause disqualification;</li>
              <li>
                3.3.2 entries are limited to one entry per e-mail address, IP address, telephone number or household. In all competitions, contestants
                will be randomly selected. This is generated using an online portal which randomises the pool of entries.
              </li>
            </ul>
          </li>
          <li>
            3.4
            <b>
              Jacaranda FM reserves the right at its sole discretion to disqualify any individual who tampers with the entry process or who provides
              false information.
            </b>
          </li>
          <li>
            3.5 A person may not win more than one prize within a three month period following the first win, nor shall any member of such person’s
            family residing at the same address be entitled to a prize within a three month period following the first win.  
            <b>
              Jacaranda FM reserves the right to withhold prizes from any such person, and to reclaim any such prize if a breach of this Rule is
              subsequently discovered.
            </b>
          </li>
          <li>
            3.6 Finalists/ winners/ contestants will be precluded from entering the same competition on a radio station where that competition is
            simultaneously, or will be flighting, on another Kagiso operated radio station. Or if there is an interest or influence from Mediamark or
            its agencies.
          </li>
        </ul>
        <h3>4 Health and Safety</h3>
        <ul>
          <li>
            4.1
            <b>
              By entering a Competition, you confirm and warrant that, to the best of your knowledge and belief, your general state of health is good
              and you have no medical condition that could be adversely affected by strenuous exercise or any of the events or activities planned or
              reasonably expected to be involved in the Competition. You confirm that you are not taking any medication at present and will not take
              any medication during the Competition which may be affected by your participation in the Competition.
            </b>
          </li>
          <li>
            4.2 You must take all reasonable steps to ensure your own health and safety when taking part in any events or activities forming part of
            the Competition or the Prize. Any behaviour or act or conduct by you which JACARANDA FM considers to pose any medical, security or safety
            risk (including without limitation any abusive behaviour, whether physical or psychological) will lead to your immediate
            disqualification. 
          </li>
          <li>
            4.3
            <b>
              You must notify JACARANDA FM of any medical or other condition which may mean that you are unfit for travel or participation in the
              Competition as soon as you become aware of such a condition. JACARANDA FM reserves the right to forfeit your place on the Competition or
              the prize (without liability or compensation) if, in its opinion, you may expose yourself or others to risk of illness or injury or to
              the cancellation, disruption, or curtailment of the Competition.
            </b>
          </li>
          <li>
            4.4 You agree not to carry on your person (or in your possession or control) or to purchase or consume during the Competition any illegal
            substance. You acknowledge and agree that alcohol consumption will only be allowed with the express prior consent of Jacaranda FM and the
            health and safety team, who will have full authority to direct that you shall not consume any more alcohol.
          </li>
          <li>
            4.5 You agree to comply with all directions of Jacaranda FM or Jacaranda FM’s representatives in respect of health and safety. 
            <b>Failure to do so may lead to immediate disqualification, or immediate withdrawal of the prize at Jacaranda FM's sole discretion.</b>
          </li>
        </ul>
        <h3>5 Prizes</h3>
        <ul>
          <li>
            5.1 The decision of the judge or presenter of each Competition will be final in all matters relating to a Competition. Once the prize has
            been handed over in terms of these Rules, the results of the Competition are final in all respects and no correspondence or negotiation
            will be allowed.
          </li>
          <li>
            5.2 Jacaranda FM will be entitled to interview you, request proof of entry, verification of identity and of address, as well as
            verification of vocal identity by means of in-studio recording in the case of an on-air entry.
            <b>
               If you refuse to provide any of these details or participate in or answer all questions raised in any interview without good reason
              then your place in the Competition will be treated as invalidated and the prize may be withdrawn.
            </b>
          </li>
          <li>
            5.3 Jacaranda FM will use all reasonable endeavours to notify prize winners via email and subsequently telephonically within twenty–eight
            (28) days from the end date of the Competition. If a selected winner does not answer his/her landline and/or mobile phone, Jacaranda FM
            will leave a message on the prize winner's landline or mobile phone (if possible). Jacaranda FM undertakes to try at least three (3) times
            on three (3) consecutive days to notify the prize winners. If Jacaranda FM cannot get hold of a prize winner telephonically or cannot
            leave a message telephonically, Jacaranda FM will send a notice to the contact details (i.e. via sms, e-mail or post) provided by the
            entrant.
          </li>
          <li>
            5.4
            <b>
              Prizes must be claimed within sixty (60) days after Jacaranda FM has notified the prize winner.  If prize winners do not collect their
              prizes within sixty (60) days after having been notified as set out above, Jacaranda FM will have the right to do with the prize
              whatever Jacaranda FM may deem to be reasonably fair.
            </b>
          </li>
          <li>
            5.5 If Jacaranda FM cannot continue with the Competition for any reason beyond its reasonable control, including errors in printing,
            production, distribution or errors made on air, or if it is required by applicable laws, Jacaranda FM may end the Competition on condition
            that – 
            <ul>
              <li>5.5.1 no prize winners have been determined; and</li>
              <li>5.5.2 reasonable notice has been given beforehand to all entrants.</li>
            </ul>
          </li>
          <li>
            5.6
            <b>
              If the Competition is ended as set out above, no entrant will have any claim of any nature whatsoever against Jacaranda FM, unless
              Jacaranda FM has been acted with gross negligence or fraudulent intent.
            </b>
          </li>
          <li>
            5.7 The prize awarded in any Competition -
            <ul>
              <li>5.7.1 cannot be transferred and will only be handed over if all details given are factually correct;</li>
              <li>5.7.2 will not be exchanged for cash amounts or for any prize other than the prize offered in that Competition;</li>
              <li>
                5.7.3 may be replaced by another prize of the same commercial value if the original prize is not available for any reason beyond
                Jacaranda FM's reasonable control.
              </li>
            </ul>
          </li>
          <li>
            5.8
            <b>
              No prizes will be transported and/or delivered to the prize winner’s place of residence, and Jacaranda FM will not be liable for any
              travel, transport, accommodation, or any other costs when the prize is handed over or received.
            </b>
          </li>
        </ul>
        <h3>6 Holiday Prizes</h3>
        <ul>
          <li>
            6.1 Prizes must be taken in accordance with the dates and destinations and number of passengers specified by Jacaranda FM. There will be
            no alternative destination.
          </li>
          <li>
            6.2 <b>Holidays are always subject to availability.</b> You must hold a valid passport with at least 6 months’ further duration and no
            visa restrictions on your ability to travel to the relevant destination you’re your Competition entry will be invalid).
          </li>
          <li>
            6.3 Holidays are not available to persons under the age of 18 unless otherwise stated in the specific terms and conditions for a
            Competition.  Children under 18 years of age must obtain written consent from a parent or guardian and cannot travel unless accompanied by
            an adult over the age of 18.
          </li>
          <li>
            6.4 All holidays must be taken within 6 months of the date of the Competition (unless agreed with Jacaranda FM or otherwise stated in the
            specific terms and conditions for the Competition) or will be deemed invalid.
          </li>
          <li>
            6.5 You must comply with the terms and limitations of any travel insurance policy offered as part of the prize, relating to the holiday.
             Where no insurance is offered, you are responsible for obtaining adequate travel insurance.
          </li>
          <li>
            6.6
            <b>
              You must comply with the terms and conditions of the airline and other transportation and venues involved in the Competition or the
              prize. There may be additional terms and conditions imposed by the provider of the prize which must be complied with and Jacaranda FM
              will not be responsible for your failure to comply with such terms and conditions.  In particular, you must comply with all health and
              safety guidelines and instructions and all applicable legal and regulatory requirements.
            </b>
          </li>
          <li>
            6.7 You are not entitled nor authorised in any way to commit Jacaranda FM to any contract, expense or cost entered into or incurred
            without its advance written acceptance of the same.
          </li>
          <li>
            6.8
            <b>
              Passport control and in-country authorities will reserve the right to refuse entry. If you are refused passage and or entry/exit to or
              from the country being visited, any additional costs incurred will be your sole responsibility.
            </b>
          </li>
          <li>
            6.9
            <b>
              Any flights, other transport, airport details, accommodation or other aspects of the prize, dates and times quoted by Jacaranda FM or
              its agents are for guidance only and are subject to change without notice with no liability arising.  You must have sufficient financial
              resources to meet any financial commitment which you may incur in connection with the prize beyond those included in the prize itself.
              This includes and without limitation, transfers to your respective airports (local and international), meals and drinks.
            </b>
          </li>
          <li>
            6.10 You must comply with and are responsible for attending to any inoculation and health regulations or visa requirements required for
            your destination.
          </li>
        </ul>
        <p>
          <h3>7 Ticket give-aways</h3>
          <br />
          <p>
            In the case of prizes in the form of free tickets, ticket holders shall be bound by and comply with the event promoter’s terms and
            conditions together with those set out on the ticket and the rules and regulations of the venue.
          </p>
        </p>
        <p>
           <h3>8 Voting</h3>
          <br />
          <p>
            For Competitions involving online voting, entrants must register a valid email address to be entitled to vote.  Only one vote for each
            valid email address will be accepted.  Jacaranda FM reserves the right to disqualify multiple votes.
          </p>
        </p>
        <h3>9 Disqualification of Entries and Cancellation of the Competition</h3>
        <ul>
          <li>
            9.1
            <b>
              You acknowledge that the interests and good reputation of Jacaranda FM and its radio stations are paramount and Jacaranda FM may, at its
              reasonable discretion, withdraw a place or require you to cease to have any involvement in the Competition if it believes it is in the
              best interests of JACARANDA FM (or its sponsors) to do so.
            </b>
          </li>
          <li>
            9.2
            <b>
              Jacaranda FM reserves the right to immediately withdraw your place on the Competition or the prize if it believes you to be in breach of
              any of your obligations, or representations and warranties, under the Rules or if you otherwise conduct yourself in a manner which is
              inappropriate or unsuitable (as determined in the reasonable discretion of Jacaranda FM). You shall be responsible for any additional
              costs in this respect (including the costs of your early return back to the country, if necessary).
            </b>
          </li>
          <li>
            9.3 <b>In the event of disqualification, JACARANDA FM reserves the right to select another entrant to take part in the Competition. </b> 
          </li>
        </ul>
        <h3>10 Confidentiality</h3>
        <ul>
          <li>
            10.1 You undertake not at any time to disclose, reveal, communicate or otherwise make public any Personal Information relating to
            Jacaranda FM, its business, personnel, servants, agents or officers to anyone.
          </li>
          <li>
            10.2 You may not publicise your involvement in the Competition or the fact that you have won a prize without the prior written permission
            of Jacaranda FM.
          </li>
        </ul>
        <h3>11 Publicity</h3>
        <ul>
          <li>
            11.1 When prize winners accept their prize, they may choose not to be identified and may refuse to have their photograph taken and
            published in printed media or to appear on radio and television.
          </li>
          <li>
            11.2 If the prize winner has given his/her written consent, Jacaranda FM has the right to publish the winner's name and photographs (at no
            fee) in any media, including advertising, promotional, print, point-of-sale or public relations material. The nature of these publications
            will be determined at the sole discretion of Jacaranda FM. 
          </li>
          <li>
            11.3 You shall not without the specific prior written consent of Jacaranda FM publish or disclose any information in connection with the
            Competition or the prize (in particular, without limitation, to a representative of the media, in whatever form). You shall not be
            entitled to give interviews or be involved in articles or reports in respect of the Competition or the prize with any third party. 
            <b>All rights in relation to the Competition and your involvement therein shall vest exclusively with Jacaranda FM.</b>
          </li>
        </ul>
        <h3>12 Intellectual Property</h3>
        <ul>
          <li>12.1 Unless otherwise stated, Competition entries will not be returned to you.</li>
          <li>
            12.2 If you have provided your written consent, as contemplated in clause 15.2 above, in respect of copyright and other intellectual
            property rights in any Competition, your entry into the Competition, and your involvement in the Competition (including, without
            limitation, all interviews, pictures, audio, audio-visual, whether your appearance is featured or incidental) in all media and technology
            (the <b>“Products”</b>), you:
            <ul>
              <li>
                (a)  hereby grant to Jacaranda FM a non-exclusive, royalty-free perpetual worldwide licence to use any such copyright and other
                intellectual property rights in any and all media to the extent Jacaranda FM deems it appropriate; and{' '}
              </li>
              <li>
                (b)  agree, at Jacaranda FM’s request, to assign to Jacaranda FM the entire such copyright and other intellectual property rights
                (including without limitation where such Products are not in existence at the date of this agreement, by way of present assignment of
                future copyright) and you agree to do all such acts as may be reasonably requested of you by Jacaranda FM to effect the assignment
                under this clauseand you agree that the licence and, if applicable, any assignment shall include the right for Jacaranda FM to alter,
                edit, compile, amend or otherwise adapt such Products and/or use such Products in conjunction with any other material and to use,
                distribute and/or broadcast such Products in all media and all formats (including transmission by way of analogue transmission,
                digital audio broadcasting, internet, satellite, television, film, cable or telephony) and the right to sub-license such rights to any
                other persons, in each case for such purposes as Jacaranda FM may in its sole discretion determine.
              </li>
            </ul>
          </li>
          <li>
            12.3
            <b>
              Any information submitted by you relating to a Competition must be personal and related specifically to you.  You warrant that any
              information which you submit to Jacaranda FM will not infringe the intellectual property, privacy or any other rights of any third
              party, and will not contain anything which is libellous, defamatory, obscene, indecent, harassing or threatening. Jacaranda FM reserves
              the right, but not the obligation, to screen, filter and/or monitor information provided by you and to edit, refuse to distribute or
              remove such information.
            </b>
          </li>
          <li>
            12.4 By entering any Competition and submitting any material or Products you warrant to Jacaranda FM that:
            <ul>
              <li>
                (a) you own or are licensed in respect of the Products submitted as part of the Competition conditions and that you have the right,
                power and authority to grant the rights set out in these Terms;
              </li>
              <li>(b) Jacaranda FM is free and able to use the Products in accordance with and in the manner set out in any relevant terms;</li>
              <li>
                (c)  you agree to waive any moral rights you may have in the Products and have obtained a waiver or all applicable moral rights in the
                Product and further have obtained all consents and permissions in respect of the Product;
              </li>
              <li>
                (d) the Products do not violate any applicable law or regulation (including any laws regarding anti-discrimination or false
                advertising);
              </li>
              <li>(e) the Products are not defamatory, trade libellous, unlawfully threatening or unlawfully harassing;</li>
              <li>(f) the Products are not obscene or pornographic; and</li>
              <li>
                (g) the Products do not, to the best of the your knowledge, contain any viruses or other computer programming routines that are
                intended to damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data or personal information.
              </li>
            </ul>
          </li>
          <li>
            12.5
            <b>
              The entrant to a Competition is the individual submitting the media (i.e. the person who owns the cellphone, telephone or who can be
              reached at the relevant email address). If the person(s) whose image is captured in any images, videos or other media is different to
              the person submitting the media, the person featured in the image shall have no claim to the prize or any part thereof.
            </b>
          </li>
          <li>
            12.6
            <b>
              You must ensure that any other person or persons whose image has been used in the relevant Products has given valid consent for the use
              of their image or has waived any rights they may have in the images, videos or other media submitted.  Failure to adequately demonstrate
              this consent to the satisfaction of Jacaranda FM may result in your disqualification from the Competition and forfeiture of any prize.
            </b>
          </li>
          <li>
            12.7 For the avoidance of doubt, all rights in the name and title of the Competition and the format rights for the Competition will vest
            exclusively in Jacaranda FM for its own use (in its sole discretion).
          </li>
        </ul>
        <h3>13 Security safeguards</h3>
        <ul>
          <li>
            13.1 Jacaranda FM is committed to securing the integrity and confidentiality of entrant's Personal Information in its possession or under
            its control by –
            <ul>
              <li>
                13.1.1 taking appropriate, reasonable technical and organisational measures to prevent loss of, damage to or unauthorised destruction
                of Personal Information, and unlawful access to or Processing of Personal Information; and
              </li>
              <li>
                13.1.2 ensuring that any operator or anyone Processing Personal Information on behalf of Jacaranda FM will Process such information
                only with the knowledge or authorisation of Jacaranda FM, and treat Personal Information which comes to their knowledge as
                confidential and not disclose it, unless required by law or in the course of the proper performance of their duties.
              </li>
            </ul>
          </li>
        </ul>
        <h3>14 Submission and collection of Personal Information</h3>
        <ul>
          <li>
            14.1 You acknowledge and agree -
            <ul>
              <li>
                14.1.1 that by entering into any Competition, Personal Information may have to be submitted and that all the Personal Information
                possessed by Jacaranda FM is collected through your entry into the Competition; 
              </li>
              <li>
                14.1.2 to grant Jacaranda FM the right to use or Process any information, data, materials or other content you provide for purposes of
                your participation in a Competition in accordance with applicable laws; and
              </li>
              <li>
                14.1.3 that information submitted will affect the quality of Personal Information obtained as well as the security in and integrity of
                Personal Information retained by Jacaranda FM.
              </li>
            </ul>
          </li>
          <li>
            14.2 Jacaranda FM collects, retains and Processes Personal Information for the following purposes - 
            <ul>
              <li>14.2.1 to allow entry into a Competition;</li>
              <li>14.2.2 to process your entry and any requests;</li>
              <li>14.2.3 to provide personalised content and information and enhance entrants' experiences;</li>
              <li>14.2.4 to analyse entries to Competitions;</li>
              <li>
                14.2.5 for market research, project planning, troubleshooting problems, detecting and protecting against error, fraud or other
                criminal activity; and
              </li>
              <li>14.2.6 for general historical, statistical and research activity.</li>
            </ul>
          </li>
          <li>
            14.3 Jacaranda FM shall retain records of personal information only for as long as necessary to achieve the purpose for which it was
            collected or processed unless -
            <ul>
              <li>14.3.1 retention of the record is required or authorised by law;</li>
              <li>14.3.2 Jacaranda FM reasonably requires the information for lawful purposes related to its functions of activities.</li>
            </ul>
          </li>
          <li>
            14.4 
            <b>
              Data collected may be shared with the prize sponsor company, but will not be sold or passed on to Third parties. By entering a
              competition the entrant has agreed to receive promotional material from selected partners. 
            </b>
          </li>
        </ul>
        <h3>15 Withdrawal of consents and requests for information and records</h3>
        <ul>
          <li>15.1 You are entitled to withdraw any consent to Process your Personal Information. </li>
          <li>
            15.2 If you withdraw your consent to Process Personal Information, you should cease any participation in the Competition forthwith. 
            <b>Any further participation will result in a renewed consent due to the binding nature of these Rules.</b> 
          </li>
          <li>
            15.3 You are entitled to request -
            <ul>
              <li>15.3.1 on proof of identity, confirmation as to whether Jacaranda FM possesses any Personal Information pertaining to you; </li>
              <li>
                15.3.2 on proof of identity, a record or a description of the Personal Information about you, including information about the identity
                of all third parties who currently have or have had, access to the Personal Information; and
              </li>
              <li>
                15.3.3 generally, any record Jacaranda FM may hold and is required to disclose to you in terms of the Promotion of Access to
                Information Act 2 of 2000.
              </li>
            </ul>
          </li>
          <li>
            15.4 You may request Jacaranda FM to –
            <ul>
              <li>
                15.4.1 correct or delete of information that is inaccurate, irrelevant, excessive, out of date, incomplete, misleading or obtained
                unlawfully;
              </li>
              <li>
                15.4.2 destroy or delete a record of personal information about you that Jacaranda FM is no longer authorised to retain in terms of
                applicable laws.  
              </li>
            </ul>
          </li>
        </ul>
        <h3>16 INDEMNITY AND EXCLUSION OF LIABILITY</h3>
        <ul>
          <li>
            16.1
            <b>To the extent legally permissible and unless Jacaranda FM acted with gross negligence or fraudulent intent –</b>
            <ul>
              <li>
                16.1.1
                <b>
                  Jacaranda FM assumes no liability whatsoever for any direct or indirect loss or damage arising from, in connection with or as a
                  result of your participation in the Competition;
                </b>
              </li>
              <li>
                16.1.2
                <b>
                  Jacaranda FM will not be liable in the event of incorrect or late entries or telephone or SMS text message entries not received as a
                  result of network incompatibility, technical faults, or otherwise;
                </b>
              </li>
              <li>
                16.1.3
                <b>
                  you indemnify Jacaranda FM against any claims of any nature arising from, in connection with or as a result of your participation,
                  in any way whatsoever, in the Competition;
                </b>
              </li>
              <li>
                16.1.4
                <b>
                  Jacaranda FM is not liable for any delay, nullification, change or substitution, or partial or total inability to execute any of
                  part or the whole of the Competition due to force majeure, war, acts of terrorism, accident, fire, flood or any other natural
                  disaster, strike or any other political crisis.
                </b>
              </li>
            </ul>
          </li>
          <li>
            16.2
            <b>
              Should JACARANDA FM or its agents become aware of any fraud, dishonesty, deceit or similar action undertaken in connection with the
              Competition or otherwise, or any act or omission which might (in JACARANDA FM’s opinion, which shall be final) have an adverse effect on
              the Competition, JACARANDA FM (or any of its radio stations), reserves the right in its discretion (without notice to you and/or without
              giving reasons) to (i) forfeit your place on the Competition; or (ii) withdraw the prize(s).
            </b>
          </li>
          <li>
            16.3
            <b>
              It is agreed and accepted by you that JACARANDA FM shall have no liability whatsoever in respect of Competitions operated by third
              parties and featured on air or on our web and social media sites.  The relevant third party will be responsible for the fulfilment of
              the Competition.  JACARANDA FM accepts no responsibility for the acts or omissions of such third parties.
            </b>
          </li>
          <li>
            16.4
            <b>
              You agree to indemnify Jacaranda FM and its radio stations and employees against all costs, losses, damages, expenses and liabilities
              (including for loss of reputation and goodwill and professional advisors fees) suffered by Jacaranda FM arising as a result of a breach
              by you of your obligations under the Rules or in any way in connection your failure to follow Jacaranda FM’s reasonable instructions
              with regard to your entry into the Competition or accepting the prize.
            </b>
          </li>
          <li>
            16.5
            <b>
              In the case of on-line Competitions you acknowledge that the internet is not a secure medium and information submitted to competitions
              hosted on websites owned or controlled by Jacaranda FM may be accessed by third parties. Jacaranda FM accepts no liability for loss
              resulting from your entry to an online Competition. 
            </b>
          </li>
        </ul>
        <h3>17 General</h3>
        <ul>
          <li>17.1 These Terms shall be governed and construed in accordance with the laws of South Africa.</li>
          <li>17.2 No variation of these Rules is effective unless approved by an authorised representative of Jacaranda FM in writing.</li>
          <li>
            17.3 The failure to exercise or delay in exercising a right or remedy provided hereunder or by law does not constitute a waiver of the
            right or remedy or waiver of other rights or remedies.
          </li>
          <li>
            17.4 These Rules are not intended to nor shall create any rights, entitlements, claims or benefits enforceable by any person that is not a
            party to them.  
          </li>
          <li>
            17.5 If any provision of the Rules are held by any competent authority to be invalid or unenforceable in whole or in part that part shall
            be severed from other terms and conditions and the validity of the other provisions of the Rules and the remainder of the provision in
            question shall not be affected. 
          </li>
        </ul>
      </div>
      <div className='overlay' onClick={() => onClose()}></div>
    </div>
  );
}
