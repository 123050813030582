import React, { useState } from 'react';
import './Navigate.css';

import Desktop1 from '../../../Images/Instructions/desktop_1.png';
import Desktop2 from '../../../Images/Instructions/desktop_2.png';
import Desktop3 from '../../../Images/Instructions/desktop_3.png';
import Phone1 from '../../../Images/Instructions/phone_1.png';
import Phone2 from '../../../Images/Instructions/phone_2.png';
import Phone3 from '../../../Images/Instructions/phone_3.png';
import Tablet1 from '../../../Images/Instructions/tablet_1.png';
import Tablet2 from '../../../Images/Instructions/tablet_2.png';
import Tablet3 from '../../../Images/Instructions/tablet_3.png';

export default function Navigate() {
  const [contentSection, setContentSection] = useState('pc');
  return (
    <div id='how-to-navigate' className='navigate-page'>
      <h1 className='title'>How to navigate</h1>

      <div className='section-tabs'>
        <div className='section-tabs-content'>
          <div className={`section-tab ${contentSection === 'pc' ? 'active' : ''}`} onClick={(e) => setContentSection('pc')}>
            <div id='tab1' className='section-text'>
              COMPUTER
            </div>
          </div>
          <div className={`section-tab ${contentSection === 'phone' ? 'active' : ''}`} onClick={(e) => setContentSection('phone')}>
            <div id='tab2' className='section-text'>
              PHONE
            </div>
          </div>
          <div className={`section-tab ${contentSection === 'tablet' ? 'active' : ''}`} onClick={(e) => setContentSection('tablet')}>
            <div id='tab3' className='section-text'>
              TABLET
            </div>
          </div>
        </div>
      </div>

      <div id='tabContent1' className={`tab-container ${contentSection === 'pc' ? '' : 'hidden'}`}>
        <div className='tab-content'>
          <h2 className='content-title'>ON YOUR COMPUTER</h2>
          <div className='image-grid'>
            <div>
              <img src={Desktop1} alt='computer VR instructions step 1' />
            </div>
            <div>
              <img src={Desktop2} alt='computer VR instructions step 2' />
            </div>
            <div>
              <img src={Desktop3} alt='computer VR instructions step 3' />
            </div>
          </div>
        </div>
      </div>
      <div id='tabContent2' className={`tab-container ${contentSection === 'phone' ? '' : 'hidden'}`}>
        <div className='tab-content'>
          <h2 className='content-title'>ON YOUR PHONE</h2>
          <div className='image-grid'>
            <div>
              <img src={Phone1} alt='phone VR instructions step 1' />
            </div>
            <div>
              <img src={Phone2} alt='phone VR instructions step 2' />
            </div>
            <div>
              <img src={Phone3} alt='phone VR instructions step 3' />
            </div>
          </div>
        </div>
      </div>
      <div id='tabContent3' className={`tab-container ${contentSection === 'tablet' ? '' : 'hidden'}`}>
        <div className='tab-content'>
          <h2 className='content-title'>ON YOUR TABLET</h2>
          <div className='image-grid'>
            <div>
              <img src={Tablet1} alt='tablet VR instructions step 1' />
            </div>
            <div>
              <img src={Tablet2} alt='tablet VR instructions step 2' />
            </div>
            <div>
              <img src={Tablet3} alt='tablet VR instructions step 3' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
