import React, { useState } from 'react';
import './App.css';
import TreasureContainer from './components/TreasureContainer/TreasureContainer';
import Loader from './components/Loader/Loader';
import WelcomeDialog from './components/WelcomeDialog/WelcomeDialog';
import LoginPage from './components/LoginPage/LoginPage';

function App() {
  return (
    <div className='App'>
      {/*<Loader />
      <div className='grid'>
        <div className='subgrid'>
          <button
            type='button'
            className='button'
            onClick={() =>
              window.TreasureHunt.setTreasureFound(0, {
                transition: false,
                displayMessage: false,
                displayConfetti: false,
              })
            }>
            Set Treasure 1
          </button>
          <button
            type='button'
            className='button'
            onClick={() =>
              window.TreasureHunt.setTreasureFound(1, {
                transition: false,
                displayMessage: false,
                displayConfetti: false,
              })
            }>
            Set Treasure 2
          </button>
          <button
            type='button'
            className='button'
            onClick={() =>
              window.TreasureHunt.setTreasureFound(2, {
                transition: false,
                displayMessage: false,
                displayConfetti: false,
              })
            }>
            Set Treasure 3
          </button>
          <button
            type='button'
            className='button'
            onClick={() =>
              window.TreasureHunt.setTreasureFound(3, {
                transition: false,
                displayMessage: false,
                displayConfetti: false,
              })
            }>
            Set Treasure 4
          </button>
          <button
            type='button'
            className='button'
            onClick={() =>
              window.TreasureHunt.setTreasureFound(4, {
                transition: false,
                displayMessage: false,
                displayConfetti: false,
              })
            }>
            Set Treasure 5
          </button>
        </div>
        <div className='subgrid'>
          <button type='button' className='button' onClick={() => window.TreasureHunt.setTreasureFound(0)}>
            Find Treasure 1
          </button>
          <button type='button' className='button' onClick={() => window.TreasureHunt.setTreasureFound(1)}>
            Find Treasure 2
          </button>
          <button type='button' className='button' onClick={() => window.TreasureHunt.setTreasureFound(2)}>
            Find Treasure 3
          </button>
          <button type='button' className='button' onClick={() => window.TreasureHunt.setTreasureFound(3)}>
            Find Treasure 4
          </button>
          <button type='button' className='button' onClick={() => window.TreasureHunt.setTreasureFound(4)}>
            Find Treasure 5
          </button>
        </div>
        <div className='subgrid'>
          <button type='button' className='button' onClick={() => window.TreasureHunt.showWelcomeDialog()}>
            Show Welcome Dialog
          </button>
          <button type='button' className='button' onClick={() => window.TreasureHunt.reset()}>
            Reset
          </button>
          <button
            type='button'
            className='button'
            onClick={() => {
              window.TreasureHunt.showLoader(2000);
            }}>
            Show Loader
          </button>
        </div>
      </div>
      <TreasureContainer />
      <WelcomeDialog />*/}

      <LoginPage />
    </div>
  );
}

export default App;
