import React, { useState } from 'react';
import SignUpForm from './Components/Sections/SignUpForm/SignUpForm';
import HowItWork from './Components/Sections/HowItWork/HowItWork';
import Navigate from './Components/Sections/Navigate/Navigate';
import FAQ from './Components/Sections/FAQ/FAQ';
import Footer from './Components/Sections/Footer/Footer';
import TermsAndConditions from './Components/Modals/TermsAndConditions/TermsAndConditions';
import UserExists from './Components/Modals/UserExists/UserExists';
import './LoginPage.css';

const DOMAIN = 'https://api.mzansivr.com/';

export default function LoginPage() {
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [showSignUpGeneralError, setShowSignUpGeneralError] = useState(false);
  const [showFullError, setShowFullError] = useState(false);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [isUserExistsModalOpen, setIsUserExistsModalOpen] = useState(false);
  const [uid, setUid] = useState(null);

  const [showThankyou, setShowThankyou] = useState(true);

  function msieversion() {

    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    if (msie > -1 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
    {
      // alert(parseInt(ua.substring(msie + 5, ua.indexOf(".", msie))));
      alert("Internet Explorer not supported. Please visit mzansiquest.com on Chrome or Firefox");

      window.location.href = "https://browsehappy.com";
      return false;
    }
    else  // If another browser, return 0
    {
      // alert('otherbrowser');
      // window.location.href = "https://browsehappy.com";
    }

    return false;
  }

  window.onload = function() {
    msieversion();
  }


  function makeParams(params) {
    const _params = [];
    for (let key in params) {
      if (params[key] !== undefined) {
        _params.push(`${key}=${encodeURIComponent(params[key])}`);
      }
    }
    return _params.join('&');
  }

  // api/isfull
  function checkIfQuestIsFull(uid, skipTutorial) {
    fetch(`${DOMAIN}api/isfull`)
      // fetch(`${DOMAIN}api/isfull`, {
      //   method: 'POST',
      //   body: JSON.stringify({
      //     uid: uid,
      //   }),
      // })
      .then((response) => response.json())
      .then((res) => {
        if (res.status) {
          window.location.href = `${DOMAIN}?${makeParams({
            hashGuest: uid,
            skip_tutorial: skipTutorial ? 1 : 0,
          })}`;
        } else {
          setIsSigningUp(false);
          setShowFullError(true);
        }
      })
      .catch((error) => {
        setIsSigningUp(false);
      });
  }

  // api/signupuser?first_name=Joe&last_name=Soap&mobile_number=0821234567&email=joesoap@gmail.com
  function onFormSubmit(formData) {
    if (isSigningUp) return;
    setIsSigningUp(true);
    setShowSignUpGeneralError(false);
    setShowFullError(false);
    setIsUserExistsModalOpen(false);

    fetch(
      `${DOMAIN}api/signupuser?${makeParams({
        first_name: formData.firstName,
        last_name: formData.surname,
        mobile_number: formData.mobileNumber,
        email: formData.email || undefined,
      })}`,
    )
      // fetch(`${DOMAIN}api/signupuser`, {
      //   method: 'POST',
      //   body: JSON.stringify({
      //     first_name: formData.firstName,
      //     last_name: formData.surname,
      //     mobile_number: formData.mobileNumber,
      //     email: formData.email || undefined,
      //   }),
      // })
      .then((response) => response.json())
      .then((res) => {
        if (res.status) {
          checkIfQuestIsFull(res.uid, formData.skipTutorial);
        } else {
          switch (res.reason) {
            case 'user_exists':
              setUid(res.uid);
              setIsUserExistsModalOpen(true);
              break;
            default:
              setShowSignUpGeneralError(true);
              break;
          }
          setIsSigningUp(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsSigningUp(false);
        setShowSignUpGeneralError(true);
      });
  }

  return (
    <div className='login-page'>
      <UserExists
        isOpen={isUserExistsModalOpen}
        onClose={() => setIsUserExistsModalOpen(false)}
        onContinue={() => {
          setIsUserExistsModalOpen(false);
          setIsSigningUp(true);
          checkIfQuestIsFull(uid, true);
        }}
      />
      <TermsAndConditions isOpen={isTermsOpen} onClose={() => setIsTermsOpen(false)} />
      <SignUpForm
        isSigningUp={isSigningUp}
        showGeneralError={showSignUpGeneralError}
        showFullError={showFullError}
        openTerms={() => setIsTermsOpen(true)}
        onSubmit={(data) => onFormSubmit(data)}
      />
      {!showThankyou && (
      <HowItWork />
      )}
      {!showThankyou && (
      <Navigate />
      )}
      {!showThankyou && (
      <FAQ />
      )}
      <Footer openTerms={() => setIsTermsOpen(true)} />
    </div>
  );
}
