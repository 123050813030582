import React, { useState, useRef, useEffect } from 'react';
import PlusIcon from './PlusIcon';
import MinusIcon from './MinusIcon';

export default function Accordion({ title, text }) {
  const [isOpen, setIsOpen] = useState(false);
  const accordionContent = useRef(null);
  const [maxHeight, setMaxHeight] = useState(null);

  useEffect(() => {
    if (isOpen) {
      if (accordionContent.current != null) {
        setMaxHeight(`${accordionContent.current.scrollHeight}px`);
      } else {
        setMaxHeight(null);
      }
    } else {
      setMaxHeight(null);
    }
  }, [isOpen]);

  function renderIcon() {
    if (isOpen) {
      return <MinusIcon />;
    } else {
      return <PlusIcon />;
    }
  }

  return (
    <div className={`accordion ${isOpen ? 'show' : ''}`}>
      <div className='accordion-title' onClick={() => setIsOpen(!isOpen)}>
        <div className='accordion-title-icon'>{renderIcon()}</div>
        <div className='accordion-title-text'>{title}</div>
      </div>
      <div
        className='accordion-content'
        style={{
          maxHeight: maxHeight,
        }}
        ref={accordionContent}>
        {text}
      </div>
    </div>
  );
}
