import React from 'react';
import PropTypes from 'prop-types';

const YoutubeEmbed = ({ embedId }) => {
  return (
    <iframe
      width='640'
      height='360'
      src={`https://www.youtube.com/embed/${embedId}?autoplay=1&playsinline=1`}
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
      title='Embedded youtube'
    />
  );
};

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
