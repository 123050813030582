import React from 'react';
import Accordion from './Accordion';
import { faq } from './FAQData';
import './FAQ.css';

export default function FAQ() {
  return (
    <div id='faq' className='faq-page'>
      <div className='title'>FAQ</div>
      <div className='accordion-container'>
        {faq.map((data, index) => (
          <Accordion key={index} {...data} />
        ))}
      </div>
      <div className="faq-support">Got another question? Please email <a href="mailto:support@mzansiquest.com">support@mzansiquest.com</a></div>
    </div>
  );
}
