import React from 'react';
import './UserExists.css';

export default function CompleteModal({ onContinue, isOpen, onClose }) {
  function handleOnContinue() {
    onContinue();
  }

  function handleOnCancel() {
    onClose();
  }

  return (
    <div className={`user-exists ${isOpen ? 'show' : ''}`}>
      <div className='user-exists-modal'>
        <div className='user-exists-content'>
          <div>
            <p>Account already exists, are you sure you would like to continue?</p>
          </div>
          <div className='buttons'>
            <button type='button' className='button keep-exploring-button' onClick={handleOnCancel}>
              CANCEL
            </button>
            <button type='button' className='button exit-button' onClick={handleOnContinue}>
              CONTINUE
            </button>
          </div>
        </div>
      </div>
      <div className='user-exists-overlay'></div>
    </div>
  );
}
