export const faq = [
  {
    title: 'Where do I start?',
    text: 'Start by completing the registration form at the top of this page, then selecting the “Start Quest” button.',
  },
  {
    title: 'What do I need to play?',
    text: 'Playing Mzansi Quest requires a computer, smartphone or tablet with a good internet connection.  The game is played in your web browser; for best experience, please use an up-to-date version of Chrome or Firefox.',
  },
  {
    title: 'What do I do in the quest?',
    text: 'Explore the virtual world to find the 5 hidden items: a gold bar, binoculars, umbrella, beaded necklace and rugby ball.  There is one item hidden in each province.  When you find an item, collect it by walking right up to the item.  Once you’ve collected all 5 items, you will be automatically entered into the draw.',
  },
  {
    title: "How do I move around?",
    text: 'Please check out the “How to navigate” section above - select your device and you’ll see instructions on how to move around.',
  },
  {
    title: 'After registering, I can\'t connect to the quest page.',
    text: 'Some company networks have security settings that may block your access to the quest webpage.  If you are trying to connect through your work internet, please try a different connection.',
  },
  {
    title: 'I’m stuck on a loading page.',
    text: 'Each area in the virtual world may take a few minutes to load - please be patient.  If nothing happens after a few minutes, try refreshing the webpage.',
  },
  {
    title: 'My sound is not working properly.',
    text: 'Other applications and web pages that use your device’s microphone (e.g. Zoom, Microsoft Teams, Skype, etc) can interfere with your audio in the quest. Please close these applications, and refresh the quest webpage.',
  },
  {
    title: 'Another player is bothering me.  What can I do?',
    text: 'If someone is bothering you, you can hide that player from your view.  Select the user menu in the top right corner of your screen, select the player’s name from the list, then select “Hide”.  Hidden players will not be able to see or hear you.',
  },
];
