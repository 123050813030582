import * as React from 'react';

function TwitterLogo(props) {
  return (
    <svg width={308} height={250} viewBox='0 0 308 250' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M96.706 250c116.043 0 179.503-96.172 179.503-179.57 0-2.731-.056-5.45-.179-8.158a128.322 128.322 0 0031.47-32.68c-11.304 5.027-23.469 8.41-36.23 9.936 13.026-7.813 23.025-20.174 27.743-34.907a126.508 126.508 0 01-40.061 15.319C247.439 7.672 231.049 0 212.901 0c-34.84 0-63.093 28.264-63.093 63.103 0 4.953.554 9.77 1.637 14.39-52.434-2.639-98.93-27.752-130.046-65.94a62.963 62.963 0 00-8.543 31.722c0 21.896 11.138 41.226 28.075 52.534a62.606 62.606 0 01-28.572-7.893c-.01.265-.01.523-.01.806 0 30.564 21.747 56.084 50.614 61.866a63.163 63.163 0 01-16.636 2.221c-4.058 0-8.013-.4-11.856-1.138 8.032 25.076 31.322 43.325 58.935 43.835-21.594 16.93-48.794 27.015-78.357 27.015A128.057 128.057 0 010 221.646c27.921 17.903 61.075 28.35 96.709 28.35'
        fill='currentColor'
      />
    </svg>
  );
}

export default TwitterLogo;
