import React, { useState } from 'react';
import YoutubeEmbed from './YoutubeEmbed';
import './HowItWork.css';

import GoldBarIcon from '../../../Images/TreasureIcons/gold_item_300x300.png';
import BinoIcon from '../../../Images/TreasureIcons/bino_item_300x300.png';
import UmbrellaIcon from '../../../Images/TreasureIcons/umbrella_item_300x300.png';
import BeadsIcon from '../../../Images/TreasureIcons/beads_item_300x300.png';
import BallIcon from '../../../Images/TreasureIcons/ball_item_300x300.png';

import Screenshot from '../../../Images/Preview/game_screenshot.png';
// import PlayIcon from '../../../Images/VideoPreview/play_button.svg';

export default function HowItWork() {
  const [watchVideo, setWatchVideo] = useState(false);

  return (
    <div id='how-it-works' className='how-it-work'>
      <div className='section-1'>
        <h1 className='title'>
          Welcome to Mzansi Quest brought to you by <br />
          Tracker and Jacaranda FM
        </h1>
        <h2 className='sub-title'>
          Explore our beautiful country virtually and <br /> find 5 hidden items to stand a chance to win R50K
        </h2>
        <div className='video-container'>
          <div className='video' onClick={() => setWatchVideo(true)}>
            <img src={Screenshot} alt='' />
          </div>
        </div>
      </div>
      <div className='section-2'>
        <div className='icons'>
          <img src={GoldBarIcon} alt='' />
          <img src={BinoIcon} alt='' />
          <img src={UmbrellaIcon} alt='' />
          <img src={BeadsIcon} alt='' />
          <img src={BallIcon} alt='' />
        </div>
        <div className='text'>These items are hidden somewhere in the virtual world of Mzansi Quest.</div>
      </div>
    </div>
  );
}
